<script setup>
import {Head, Link} from '@inertiajs/vue3';
import {vueVimeoPlayer as VimeoPlayer} from 'vue-vimeo-player'
import {onMounted, onUnmounted, ref} from "vue";
import {PlayCircleIcon, StarIcon} from '@heroicons/vue/20/solid'
import throttle from 'lodash/throttle';
import ShippingPriceCalculatorV2 from "@/Components/ShippingPriceCalculatorV2.vue";
import ContactUsDialogue from "@/Components/ContactUsDialogue.vue";

const props = defineProps({
  testimonials: {
    type: Array,
  },
  blog_posts: {
    type: Array
  },
  og_image: String,
});

let isMobile = ref();
const isServer = typeof document === 'undefined';
let resizeHandler = null;
let canvasOffsetTop = null;
let showVideo = ref(false);
const player = ref(null);
let observer = null;

onMounted(() => {
  isMobile.value = window.innerWidth <= 640;
  resizeHandler = window.addEventListener('resize', () => {
    isMobile.value = window.innerWidth <= 640;
    canvasOffsetTop = document.getElementById('how-it-works').offsetTop;
    reGenerateLine();
  });
  if (window.innerWidth >= 1024) {
    calculateLogoPositioning();
    window.addEventListener('scroll', doOnScroll);
  } else {
    document.getElementById('page-logo').classList.add('hidden');
  }

  let video = document.querySelector('.video');
  if (video && !isServer) {
    observer.observe(video);
  }

});

onUnmounted(() => {
  window.removeEventListener('resize', resizeHandler);
  window.removeEventListener('scroll', doOnScroll);

  if (headerLogo) {
    headerLogo.classList.remove('hidden');
    header.classList.add('bg-white');
  }

  if (!isServer) {
    observer.disconnect();
  }

});

//if the video comes into view, showVideo is set to true
if (!isServer) {
  observer = new IntersectionObserver((entries) => {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        if (!showVideo.value) {
          showVideo.value = true;
        } else {
          player.value.play();
        }
      } else {

        if (showVideo.value) {
          player.value.pause();
        }

      }
    });
  }, {threshold: 0.5});
}


let lastKnownScrollPosition = 0;
let ticking = false;
let openContactUsDialogue = ref(false);

function openContactUsDialogueFn() {
  openContactUsDialogue.value = true;
  gtag('event', 'contact_us_opened', {
    'event_category': 'engagement',
    'event_label': 'contact_us_opened'
  });
}

let doOnScroll = function () {
  lastKnownScrollPosition = window.scrollY;

  if (!ticking) {
    window.requestAnimationFrame(function () {
      animateLogo(lastKnownScrollPosition);
      ticking = false;
    });

    ticking = true;
  }
}

let headerLogoTop = null;
let pageLogoTop = null;
let headerLogo = null;
let pageLogo = null;
let header = null;

function animateLogo(scrollPos) {
  //scale the logo from 1.5 to 1 based on the position of the logos and the scroll position
  let initial_distance = pageLogoTop - headerLogoTop;
  let distance = initial_distance - scrollPos;
  let percentage = (distance / initial_distance);

  if (percentage >= 0) {
    pageLogo.classList.remove('hidden');
    headerLogo.classList.add('hidden');
    header.classList.remove('bg-white');
    let scale = 1 + (percentage * 0.5);

    pageLogo.style.transform = `scale(${scale})`;
  }

  if (percentage <= 0) {
    pageLogo.classList.add('hidden');
    headerLogo.classList.remove('hidden');
    header.classList.add('bg-white');
  }

}

function calculateLogoPositioning() {
  headerLogo = document.getElementById('header-logo');
  pageLogo = document.getElementById('page-logo');
  header = document.getElementById('header');

//get the top position of both logos
  headerLogoTop = headerLogo.getBoundingClientRect().top;
  pageLogoTop = pageLogo.getBoundingClientRect().top;

  animateLogo(0);
}


const calculateCenterCordsForEl = (el) => {
  let element = document.querySelector(el);
  let rect = element.getBoundingClientRect();
  let docEl = document.documentElement;
  let body = document.body;

  let scrollTop = window.pageYOffset || docEl.scrollTop || body.scrollTop;

  let cords = {
    x: (rect.left + rect.width / 2),
    y: (rect.top + rect.height / 2) + scrollTop - canvasOffsetTop
  };
  return [cords.x, cords.y];
}

const calculateCenterTopCordsForEl = (el) => {
  let element = document.querySelector(el);
  let rect = element.getBoundingClientRect();
  let docEl = document.documentElement;
  let body = document.body;

  let scrollTop = window.pageYOffset || docEl.scrollTop || body.scrollTop;

  let cords = {
    x: (rect.left + rect.width / 2),
    y: (rect.top + rect.height / 2) + scrollTop - canvasOffsetTop - rect.height
  };
  return [cords.x, cords.y];
}

//reGenerateLine function that is throttled calling generateLine
const reGenerateLine = throttle(generateLine, 1000);

function generateLine() {
  const box_9a_cords = calculateCenterCordsForEl('.pin-box-9');

  let container = document.getElementById('how-it-works');
  let svg = document.getElementById('how-it-works-svg');

  let canvasWidth = container.offsetWidth;
  let canvasHeight = container.offsetHeight;
  canvasOffsetTop = container.offsetTop;

  svg.setAttribute("viewBox", "0 0 " + canvasWidth + " " + canvasHeight);

  const points = [];

  let box_1_top_cords = calculateCenterTopCordsForEl('.box-1');
  let box_1_cords = calculateCenterCordsForEl('.box-1');
  let box_2_cords = calculateCenterCordsForEl('.box-2');
  if (window.innerWidth > 435) {
    var box_3a_cords = calculateCenterCordsForEl('.pin-box-3');
  }
  let box_3_cords = calculateCenterCordsForEl('.box-3');
  let box_4_cords = calculateCenterCordsForEl('.box-4');
  let box_5_cords = calculateCenterCordsForEl('.box-5');
  let box_6_cords = calculateCenterCordsForEl('.box-6');
  let box_7_cords = calculateCenterCordsForEl('.box-7');
  let box_8_cords = calculateCenterCordsForEl('.box-8');
  let box_9_cords = calculateCenterCordsForEl('.box-9');
  if (window.innerWidth > 435) {
    box_9a_cords[0] += 100;
    box_9a_cords[1] += 300;
  }


  points.push(box_1_top_cords);
  points.push(box_1_cords);
  points.push(box_2_cords);
  if (window.innerWidth > 435) {
    points.push(box_3a_cords);
  }
  points.push(box_3_cords);
  points.push(box_4_cords);
  points.push(box_5_cords);
  points.push(box_6_cords);
  points.push(box_7_cords);
  points.push(box_8_cords);
  points.push(box_9_cords);
  if (window.innerWidth > 435) {
    points.push(box_9a_cords);
  }


  let strokeWidth = window.innerWidth > 435 ? 300 : 100;

  let translateLeftAmount = window.innerWidth > svg.clientWidth ? (window.innerWidth - svg.clientWidth) / 2 : 0;

  const svgPath = (points, command) => {
    // build the d attributes by looping over the points
    const d = points.reduce((acc, point, i, a) => i === 0
            // if first point
            ? `M ${point[0]},${point[1]}`
            // else
            : `${acc} ${command(point, i, a)}`
        , '')
    return `<path d="${d}" fill="none" id="lines" stroke="#fff" stroke-width="${strokeWidth}px" style="transform:translate3d(-${translateLeftAmount}px, 0, 0)" />`
  }
  // F8F4E3

  const line = (pointA, pointB) => {
    const lengthX = pointB[0] - pointA[0]
    const lengthY = pointB[1] - pointA[1]
    return {
      length: Math.sqrt(Math.pow(lengthX, 2) + Math.pow(lengthY, 2)),
      angle: Math.atan2(lengthY, lengthX)
    }
  }

  const controlPoint = (current, previous, next, reverse) => {
    // When 'current' is the first or last point of the array
    // 'previous' or 'next' don't exist.
    // Replace with 'current'
    const p = previous || current
    const n = next || current
    // The smoothing ratio
    const smoothing = 0.2
    // Properties of the opposed-line
    const o = line(p, n)
    // If is end-control-point, add PI to the angle to go backward
    const angle = o.angle + (reverse ? Math.PI : 0)
    const length = o.length * smoothing
    // The control point position is relative to the current point
    const x = current[0] + Math.cos(angle) * length
    const y = current[1] + Math.sin(angle) * length
    return [x, y]
  }

  const bezierCommand = (point, i, a) => {
    // start control point
    const [cpsX, cpsY] = controlPoint(a[i - 1], a[i - 2], point)
    // end control point
    const [cpeX, cpeY] = controlPoint(point, a[i - 1], a[i + 1], true)
    return `C ${cpsX},${cpsY} ${cpeX},${cpeY} ${point[0]},${point[1]}`
  }

  // const lineCommand = point => `L ${point[0]} ${point[1]}`;

  svg.innerHTML = svgPath(points, bezierCommand)
  // svg.innerHTML = svgPath(points, lineCommand)

}
</script>

<template>
  <Head>
    <title>E-commerce delivery made easy</title>
    <meta content="E-commerce delivery made easy" head-key="og:title" property="og:title">
    <meta content="https://samos-e.com" head-key="og:url" property="og:url">
    <meta content="website" head-key="og:type" property="og:type">
    <meta
        content="Ship your online products your way, with SAMOS shipping. Track your order, get help with delivery and set up an integrated shopping platform."
        head-key="description"
        name="description"/>
    <meta
        content="Ship your online products your way, with SAMOS shipping. Track your order, get help with delivery and set up an integrated shopping platform."
        head-key="og:description"
        property="og:description">
    <meta :content="og_image" head-key="og:image" property="og:image">
    <link head-key="canonical" href="https://samos-e.com/" rel="canonical">
  </Head>


  <div class="relative isolate">
    <svg
        aria-hidden="true"
        class="absolute inset-0 -z-10 h-full w-full stroke-electric-violet-100 [mask-image:radial-gradient(white,transparent_70%)]">
      <defs>
        <pattern id="83fd4e5a-9d52-42fc-97b6-718e5d7ee527" height="200" patternUnits="userSpaceOnUse" width="200"
                 x="50%"
                 y="-1">
          <path d="M100 200V.5M.5 .5H200" fill="none"/>
        </pattern>
      </defs>
      <svg class="overflow-visible fill-gray-50" x="50%" y="-1">
        <path d="M-100.5 0h201v201h-201Z M699.5 0h201v201h-201Z M499.5 400h201v201h-201Z M-300.5 600h201v201h-201Z"
              stroke-width="0"/>
      </svg>
      <rect fill="url(#83fd4e5a-9d52-42fc-97b6-718e5d7ee527)" height="100%" stroke-width="0" width="100%"/>
    </svg>
    <div class="mx-auto max-w-7xl px-6 py-24 sm:py-32 lg:flex lg:items-center lg:gap-x-10 lg:px-8 lg:py-40">
      <div class="mx-auto max-w-2xl lg:mx-0 lg:flex-auto">

        <div class="h-8 relative">
          <svg id="page-logo" class="h-8 w-auto absolute z-10"
               style="transform-origin: bottom left;"
               viewBox="0 0 185 40">
            <g fill="#000">
              <path
                  d="m16.8 9.6c-1-2.8-2.9-3.4-4.8-3.4-2.8 0-4.5 1.7-4.5 4 0 2.1 1.8 3.1 3.7 3.7l4.4 1.5c5.8 1.8 7.4 5.5 7.4 8.8 0 5.9-5.1 9.4-11.1 9.4-5.4 0-10.5-2.8-11.9-8.2l5.3-1.3c.7 2.5 3.5 4.3 6.6 4.3s5.3-1.8 5.3-4.2c0-2.1-1.7-3.2-3.9-3.9l-4.1-1.4c-4.9-1.5-7.6-4.2-7.6-8.5.1-5.4 4.1-9.4 10.2-9.4 4.3 0 8.5 1.8 10.3 7.4z"></path>
              <path
                  d="m43.1 1c4.1 0 8.2 1.4 11.1 5.7v-5h6v31.3h-6v-5c-2.9 4.2-7 5.7-11.1 5.7-9.2 0-15.2-7.4-15.2-16.3s6-16.4 15.2-16.4zm.8 5.3c-6.4 0-10 5-10 10.9s3.6 10.9 10 10.9c6.2 0 10.4-4.6 10.5-10.9-.1-6.2-4.3-10.9-10.5-10.9z"></path>
              <path
                  d="m89.9 15.3c0-6.6-3-8.9-6.3-8.9s-8.7 2.2-8.7 12.1v14.5h-6v-31.3h6v6.3c1.3-4.6 5.4-7 9.9-7 2.9 0 7.4 1 9.8 6.6 2.5-5.5 6.7-6.6 10.4-6.6 7.2 0 11.9 4.2 11.9 14.3v17.6h-6v-17.6c0-6.6-3-8.9-6.3-8.9-2.9 0-8.4 1.8-8.7 11.3v15.2h-6z"></path>
              <path
                  d="m178.8 9.6c-1-2.8-2.9-3.4-4.8-3.4-2.8 0-4.5 1.7-4.5 4 0 2.1 1.8 3.1 3.7 3.7l4.4 1.5c5.8 1.8 7.4 5.5 7.4 8.8 0 5.9-5.1 9.4-11.1 9.4-5.4 0-10.4-2.8-11.9-8.2l5.3-1.3c.7 2.5 3.5 4.3 6.6 4.3s5.3-1.8 5.3-4.2c0-2.1-1.7-3.2-3.9-3.9l-4.1-1.4c-4.9-1.5-7.5-4.2-7.5-8.5 0-5.3 4.1-9.3 10.1-9.3 4.3 0 8.5 1.8 10.3 7.4z"></path>
              <path
                  d="m140.4 0c-9.3 0-16.9 7.7-16.9 17.1v1c0 .2 0 .5.1.7 1.2 13.2 16.8 21.1 16.8 21.1s15.8-8 16.9-21.1c0-.2 0-.5 0-.7 0-.3 0-.7 0-1 0-9.4-7.6-17.1-16.9-17.1zm10.7 17.2c0 6.3-4.8 11.4-10.7 11.4s-10.6-5.1-10.6-11.4c0-6.4 4.8-11.5 10.6-11.5 5.9-.1 10.7 5.1 10.7 11.5z"></path>
            </g>
          </svg>
        </div>

        <h1 class="mt-10 max-w-lg text-4xl font-bold tracking-tight text-electric-violet-700 sm:text-6xl">Simple shipping to the EU and beyond</h1>
        <p class="mt-6 text-lg leading-8 text-electric-violet-600">International one stop shop <span
            class="sr-only">(<abbr
            title="International one stop shop">IOSS</abbr>)</span> for online retailers, big and small. Ensure your parcels sail through customs and reach their destinations on time.
        </p>
        <ol class="mt-6 text-lg leading-8 text-electric-violet-600 list-disc list-inside">
          <li>No hidden fees or charges to your customers</li>
          <li>Ship with or without <abbr>IOSS</abbr> to the EU</li>
          <li>Delivered within 2-6 working days</li>
        </ol>
        <div class="mt-10 flex items-center gap-x-6 mb-16">

          <p class="text-electric-violet-600">Is SAMOS for me?</p>

          <button
              class="text-sm leading-6 text-electric-violet-600 border border-electric-violet-600 px-6 py-2.5 rounded-full hover:bg-gray-50 uppercase"
              @click="openContactUsDialogueFn()">
            Talk to us
          </button>
          <contact-us-dialogue v-model="openContactUsDialogue"></contact-us-dialogue>
        </div>
      </div>
      <div class="mt-16 sm:mt-24 lg:mt-0 lg:flex-shrink-0 lg:flex-grow">
        <shipping-price-calculator-v2 id="get-a-quote"></shipping-price-calculator-v2>
      </div>
    </div>
  </div>

  <section class="overflow-hidden mb-32">
    <div
        class="flex flex-col max-w-7xl items-center justify-between pt-6 xl:px-8 xl:mx-auto">
      <div class="w-full">
        <h3 class="text-center text-3xl mb-4 px-3">They trust us to ship around the world</h3>

        <div class="py-12 flex justify-center">
          <div class="mx-auto">
            <div
                class="mx-auto grid max-w-lg grid-cols-4 items-center gap-x-8 gap-y-12 sm:max-w-xl sm:grid-cols-6 sm:gap-x-10 sm:gap-y-14 lg:mx-0 lg:grid-cols-5">
              <Link href="/customer-stories/the-grooming-clinic">
                <img alt="The grooming clinic logo" class="col-span-2 max-h-12 w-full object-contain lg:col-span-1"
                     height="56" loading="lazy"
                     src="../../img/customer-stories/tgc/tgc-logo.svg"
                     width="128"/>
              </Link>
              <Link href="/customer-stories/genuine-motors">
                <img alt="Genuine motors logo" class="col-span-2 max-h-12 w-full object-contain lg:col-span-1"
                     height="200" loading="lazy"
                     src="../../img/customer-stories/gm/gm-logo.svg"
                     width="144"/>
              </Link>
              <Link href="/customer-stories/bofi-racing">
                <img alt="BOFI racing logo" class="col-span-2 max-h-12 w-full object-contain lg:col-span-1"
                     height="150" loading="lazy"
                     src="../../img/customer-stories/bofi/bofi-logo.svg"
                     width="131"/>
              </Link>
              <Link href="/customer-stories/camtraptions">
                <img alt="Camtraptions logo"
                     class="col-span-2 max-h-12 w-full object-contain sm:col-start-2 lg:col-span-1"
                     height="208" loading="lazy"
                     src="../../img/customer-stories/camtraptions/camtraptions-logo.svg"
                     width="208"/>
              </Link>
              <img alt="Alexandria UK logo"
                   class="col-span-2 col-start-2 max-h-12 w-full object-contain sm:col-start-auto lg:col-span-1"
                   height="250"
                   loading="lazy"
                   src="../../img/customer-stories/alexandriauk/alexandriauk-logo.svg"
                   width="177"/>
            </div>
          </div>
        </div>
        <div class="mt-4 flex justify-center">
          <p class="relative rounded-full bg-gray-50 px-4 py-1.5 text-sm leading-6 text-gray-600 ring-1 ring-inset ring-gray-900/5">
            <span
                class="hidden md:inline">Hundreds of companies use SAMOS to ship their products all around the globe</span>
            <Link class="font-semibold text-electric-violet-600" href="/customer-stories"><span aria-hidden="true"
                                                                                                class="absolute inset-0"/> Read our customer stories
            </Link>
          </p>
        </div>

      </div>
    </div>
  </section>


  <section class="overflow-hidden -mt-9">
    <div class="flex flex-col max-w-7xl items-center justify-between xl:px-8 xl:mx-auto video">
      <div
          class="bg-pot-pourri-100 text-electric-violet-600 w-full rounded-t-5xl pt-16 pb-28 px-6 xl:px-12 border border-electric-violet-500">

        <div class="gap-12 flex-col md:flex-row flex">

          <div v-if="!showVideo" class="relative cursor-pointer md:w-3/4 rounded-2xl overflow-hidden">
            <div class="absolute w-full h-full flex justify-center items-center" @click="showVideo=true">
                            <span class="bg-white rounded-full">
                                <PlayCircleIcon class="h-16 w-16 text-black"/>
                            </span>
            </div>
            <picture>
              <source
                  sizes="(max-width: 1572px) 100vw, 1572px"
                  srcset="../../img/video-poster/video-poster_txdbtv_c_scale-w_320.avif 320w, ../../img/video-poster/video-poster_txdbtv_c_scale-w_1369.avif 1369w, ../../img/video-poster/video-poster_txdbtv_c_scale-w_1572.avif 1572w"
                  type="image/avif"
              >
              <source
                  sizes="(max-width: 1572px) 100vw, 1572px"
                  srcset="../../img/video-poster/video-poster_grouzf_c_scale-w_320.webp 320w, ../../img/video-poster/video-poster_grouzf_c_scale-w_904.webp 904w, ../../img/video-poster/video-poster_grouzf_c_scale-w_1363.webp 1363w, ../../img/video-poster/video-poster_grouzf_c_scale-w_1572.webp 1572w"
                  type="image/webp"
              >
              <img alt="SAMOS Video Poster" class="aspect-video w-full" loading="lazy"
                   src="../../img/video-poster/video-poster.jpg">
            </picture>
          </div>

          <div v-if="showVideo" class="md:w-3/4 rounded-2xl overflow-hidde relative aspect-video">
            <vimeo-player ref="player" :autoplay="true" :muted="true" :options="{muted:true}" :video-id="'694053981'"
                          class="h-full w-full video-container rounded-2xl"/>
          </div>

          <div class="md:w-1/4 flex items-center">
            <p>SAMOS integrates with the most popular e-commerce platforms to help you improve your delivery process, maximise sales and provide a more seamless shopping experience.</p>
          </div>
        </div>

      </div>
    </div>
  </section>


  <section id="how-it-works" class="relative pb-16 lg:pb-32 xl:pb-48 -mt-10 overflow-hidden max-w-7xl mx-auto xl:px-8">
    <div
        class="absolute bg-cape-palliser-100 w-full xl:w-[calc(100%-4rem)] h-full overflow-hidden rounded-t-5xl border border-b-0 border-electric-violet-500">
      <svg id="how-it-works-svg" class="left-0 top-0 h-full w-full svg" viewBox="0 0 200 200"></svg>
    </div>
    <div class="max-w-7xl mx-auto">

      <div class="gap-12 relative">

        <h3 class="text-electric-violet-600 text-3xl sm:text-4xl lg:text-5xl font-medium leading-tight pt-20 z-10 md:relative pl-6 xl:pl-12">How it works</h3>

        <div v-if="isMobile" class="block px-6">

          <section class="flex flex-col gap-4 mt-12">
            <div>
              <img alt="An illustration of a bag with a computer screen next to it"
                   class="box-1 samos-drop-shadow aspect-square w-full object-contain"
                   loading="lazy" src="../../img/how-it-works/box-1.svg">
            </div>
            <div class="text-electric-violet-600">
              <p>Your customers make a purchase on your retail website/marketplace</p>
            </div>
          </section>

          <section class="flex flex-col gap-4 mt-12">
            <div>
              <img alt="An illustration of a shipping label with name, surname and address"
                   class="box-2 samos-drop-shadow aspect-square w-full object-contain"
                   loading="lazy" src="../../img/how-it-works/box-2.svg">
            </div>
            <div class="text-electric-violet-600">
              <p>Their details get sent to our system if it’s integrated with your retail platform - or you can enter them yourself</p>
            </div>
          </section>


          <section class="flex flex-col gap-4 mt-12">
            <div class="pin-box-3"></div>
            <div>
              <img alt="An illustration of a shipping box"
                   class="box-3 samos-drop-shadow aspect-square w-full object-contain" loading="lazy"
                   src="../../img/how-it-works/box-3.svg">
            </div>
            <div class="text-electric-violet-600">
              <p>Parcel labels are generated for you to print off and attach to individual orders. You'll also get a unique tracking number to use throughout the shipping process</p>
            </div>
          </section>


          <section class="flex flex-col gap-4 mt-12">
            <div>
              <img alt="An illustration of a large bag"
                   class="box-4 samos-drop-shadow aspect-square w-full object-contain" loading="lazy"
                   src="../../img/how-it-works/box-4.svg">
            </div>
            <div class="text-electric-violet-600">
              <p>You get all of your individual parcels together in one large box or bag ready for shipping</p>
            </div>
          </section>

          <section class="flex flex-col gap-4 mt-12">
            <div>
              <img alt="An illustration of a truck"
                   class="box-5 samos-drop-shadow aspect-square w-full object-contain" loading="lazy"
                   src="../../img/how-it-works/box-5.svg">
            </div>
            <div class="text-electric-violet-600">
              <p>You can either send your shipment to us, drop it off at a collection point or we can collect it from you</p>
            </div>
          </section>


          <section class="flex flex-col gap-4 mt-12">
            <div>
              <img alt="An illustration of an open cardboard box"
                   class="box-6 samos-drop-shadow aspect-square w-full object-contain" loading="lazy"
                   src="../../img/how-it-works/box-6.svg">
            </div>
            <div class="text-electric-violet-600">
              <p>When we get your shipment, we open it and process the parcels and data</p>
            </div>
          </section>

          <section class="flex flex-col gap-4 mt-12">
            <div>
              <img alt="An illustration of a map with arrows pointing to all over europe"
                   class="box-7 samos-drop-shadow aspect-square w-full object-contain"
                   loading="lazy" src="../../img/how-it-works/box-7.svg">
            </div>
            <div class="text-electric-violet-600">
              <p>We then take your parcels to Europe using our own transport, and customs clear according to<br> your instructions
              </p>
            </div>
          </section>


          <section class="flex flex-col gap-4 mt-12">
            <div>
              <img alt="An illustration of a hand with a box on top next to a door"
                   class="box-8 samos-drop-shadow aspect-square w-full object-contain"
                   loading="lazy" src="../../img/how-it-works/box-8.svg">
            </div>
            <div class="text-electric-violet-600">
              <p>Finally, we send your parcels to your customers using trusted final-mile delivery partners</p>
            </div>
          </section>

          <section>
            <div class="box-9"></div>
          </section>


        </div>

        <!-- Desktop version-->
        <div v-if="!isMobile" class="block px-6">
          <section class="grid gap-4 grid-rows-none grid-cols-none">
            <div class="col-start-3 row-start-2 col-span-1 row-span-1">
              <img alt="An illustration of a bag with a computer screen next to it"
                   class="box-1 samos-drop-shadow -mt-16 max-w-xs lg:max-w-none aspect-square"
                   loading="lazy" src="../../img/how-it-works/box-1.svg"
                   @load="reGenerateLine">
            </div>
            <div class="text-electric-violet-600 text-right col-span-2 row-start-2 self-end pb-32">
              <p>Your customers make a<br> purchase on your retail<br> website/marketplace</p>
            </div>
          </section>

          <section class="grid grid-cols-3 gap-4 grid-rows-none">
            <div class="col-start-1 row-start-2 col-span-1 row-span-1">
              <img alt="An illustration of a shipping label with name, surname and address"
                   class="box-2 samos-drop-shadow aspect-square object-contain"
                   loading="lazy" src="../../img/how-it-works/box-2.svg"
                   @load="reGenerateLine">
            </div>
            <div class="text-electric-violet-600 text-left col-span-2 row-start-2 self-end pb-18">
              <p>Their details get sent to our<br> system if it’s integrated with<br> your retail platform - or you<br> can enter them yourself
              </p>
            </div>
          </section>


          <section class="grid grid-cols-3 gap-4 grid-rows-none mt-32 xl:mt-48 relative">
            <div class="col-start-2 pin-box-3 absolute"></div>
            <div class="col-start-2 row-start-2 col-span-1 row-span-1">
              <img alt="An illustration of a shipping box"
                   class="box-3 samos-drop-shadow max-w-xs lg:max-w-full aspect-square object-contain" loading="lazy"
                   src="../../img/how-it-works/box-3.svg"
                   @load="reGenerateLine">
            </div>
            <div class="text-electric-violet-600 text-right col-span-1 row-start-2 pt-20">
              <p>Parcel labels are generated<br class="hidden lg:block"> for you to print off and attach<br
                  class="hidden lg:block"> to individual orders. You'll<br
                  class="hidden lg:block"> also get a unique tracking<br
                  class="hidden lg:block"> number to use throughout the<br class="hidden lg:block"> shipping process</p>
            </div>
          </section>


          <section class="grid grid-cols-3 gap-4 grid-rows-none">
            <div class="col-start-3 row-start-2 col-span-1 row-span-1">
              <img alt="An illustration of a large bag" class="box-4 samos-drop-shadow aspect-square object-contain"
                   loading="lazy" src="../../img/how-it-works/box-4.svg" @load="reGenerateLine">
            </div>
            <div class="text-electric-violet-600 text-left col-start-3 row-start-3 pl-0 md:pl-6">
              <p>You get all of your individual<br class="hidden lg:block"> parcels together in one<br
                  class="hidden lg:block"> large box or bag ready for<br class="hidden lg:block"> shipping</p>
            </div>
          </section>

          <section class="grid grid-cols-3 gap-4 grid-rows-none -mt-28">
            <div class="col-start-1 row-start-2 col-span-1 row-span-1">
              <img alt="An illustration of a truck" class="box-5 samos-drop-shadow aspect-square object-contain"
                   loading="lazy" src="../../img/how-it-works/box-5.svg" @load="reGenerateLine">
            </div>
            <div class="text-electric-violet-600 text-left col-start-1 row-start-3">
              <p>You can either send your<br class="hidden lg:block"> shipment to us, drop it off at a<br
                  class="hidden lg:block"> collection point or we can<br class="hidden lg:block"> collect it from you
              </p>
            </div>
          </section>


          <section class="grid grid-cols-2 gap-4 grid-rows-none">
            <div class="col-start-2 row-start-2 col-span-1 row-span-1">
              <img alt="An illustration of an open cardboard box"
                   class="box-6 samos-drop-shadow aspect-square object-contain"
                   loading="lazy" src="../../img/how-it-works/box-6.svg" @load="reGenerateLine">
            </div>
            <div class="text-electric-violet-600 text-left col-start-3 row-start-2 self-center">
              <p>When we get your<br> shipment, we open it<br> and process the<br> parcels and data</p>
            </div>
          </section>

          <section class="grid grid-cols-2 gap-4 grid-rows-none">
            <div class="col-start-1 row-start-2 col-span-1 row-span-1">
              <img alt="An illustration of a map with arrows pointing to all over europe"
                   class="box-7 samos-drop-shadow aspect-square object-contain"
                   loading="lazy" src="../../img/how-it-works/box-7.svg"
                   @load="reGenerateLine">
            </div>
            <div class="text-electric-violet-600 text-left col-span-2 row-start-2 self-end pb-18">
              <p>We then take your parcels to<br> Europe using our own transport,<br> and customs clear according to<br> your instructions
              </p>
            </div>
          </section>


          <section class="grid grid-cols-3 gap-4 grid-rows-none mt-28">
            <div class="col-start-2 row-start-2 col-span-1 row-span-1">
              <img alt="An illustration of a hand with a box on top next to a door"
                   class="box-8 samos-drop-shadow aspect-square object-contain"
                   loading="lazy" src="../../img/how-it-works/box-8.svg"
                   @load="reGenerateLine">
            </div>
            <div class="text-electric-violet-600 text-left col-start-2 row-start-3 pl-0 lg:pl-24">
              <p>Finally, we send your parcels to<br class="hidden lg:block"> your customers using trusted<br
                  class="hidden lg:block"> final-mile delivery partners</p>
            </div>
          </section>

          <section class="grid grid-cols-3 gap-4 grid-rows-none">
            <div class="col-start-1 row-start-2 col-span-1 row-span-1 box-9"></div>
          </section>

        </div>

      </div>
      <div class="pin-box-9 w-12 absolute mt-24"></div>


    </div>

  </section>

  <section>
    <div class="flex flex-col max-w-7xl items-center justify-between xl:px-8 xl:mx-auto">
      <div class="bg-cape-palliser-100 border border-t-0 border-electric-violet-500 w-full">
        <div
            class="bg-cape-palliser-500 text-white w-full rounded-t-5xl pt-16 pb-28 px-6 xl:px-12 border border-x-0 border-electric-violet-500">
          <h3 class="text-center text-4xl">From the blog</h3>

          <div
              class="mx-auto mt-12 grid max-w-2xl grid-cols-1 gap-x-6 xl:gap-x-8 gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-3">
            <article v-for="article in blog_posts" :key="article.id" class="flex flex-col items-start justify-between">
              <Link
                  :href="article.category ? '/blog/' + article.category.slug + '/' + article.slug : '/blog/' + article.slug"
                  class="relative w-full">
                <picture v-if="article.list_image">
                  <source :srcset="article.list_image.srcset_webp" type="image/webp">
                  <source :srcset="article.list_image.srcset" :type="article.list_image.mime_type">
                  <img :alt="article.list_image.alt ? article.list_image.alt : article.title"
                       :src="article.list_image.url"
                       class="aspect-[16/9] w-full rounded-2xl bg-gray-100 object-cover sm:aspect-[2/1] lg:aspect-[3/2]"
                       loading="lazy"
                       onload="if(this.dataset.sized===undefined){this.sizes=Math.ceil(this.getBoundingClientRect().width/window.innerWidth*100)+'vw';this.dataset.sized=''}"
                       sizes="1px">
                </picture>
                <div class="absolute inset-0 rounded-2xl ring-1 ring-inset ring-gray-900/10"></div>
              </Link>
              <div class="max-w-xl">
                <div class="mt-8 flex items-center gap-x-4 text-xs">
                  <time :datetime="article.published_at" class="text-white">{{ article.human_readable_published_at }}
                  </time>
                  <Link v-if="article.category"
                        :href="'/blog/' + article.category.slug"
                        class="relative z-10 rounded-full bg-gray-50 px-3 py-1.5 font-medium text-gray-600 hover:bg-gray-100 capitalize">{{ article.category.title }}
                  </Link>
                </div>
                <div class="group relative">
                  <h3 class="mt-3 text-lg font-semibold leading-6 text-white">
                    <Link
                        :href="article.category ? '/blog/' + article.category.slug + '/' + article.slug : '/blog/' + article.slug">
                      <span class="absolute inset-0"></span>
                      {{ article.title }}
                    </Link>
                  </h3>
                  <p class="mt-5 line-clamp-3 text-sm leading-6 text-white">{{ article.excerpt }}</p>
                </div>
                <div v-if="article.user" class="relative mt-8 flex items-center gap-x-4">
                  <picture v-if="article.user.avatar">
                    <source :srcset="article.user.avatar.srcset_webp" type="image/webp">
                    <source :srcset="article.user.avatar.srcset" :type="article.user.avatar.mime_type">
                    <img :alt="'Picture of ' + article.user.name" :src="article.user.avatar.url"
                         class="h-10 w-10 rounded-full bg-gray-100"
                         loading="lazy"
                         onload="if(this.dataset.sized===undefined){this.sizes=Math.ceil(this.getBoundingClientRect().width/window.innerWidth*100)+'vw';this.dataset.sized=''}"
                         sizes="1px"
                         src="">
                  </picture>
                  <div class="text-sm leading-6">
                    <p class="font-semibold text-white">
                      <span class="absolute inset-0"></span>
                      {{ article.user.name }}
                    </p>
                    <p class="text-white">{{ article.user.position }}</p>
                  </div>
                </div>
              </div>
            </article>
          </div>

        </div>
      </div>
    </div>
  </section>

  <section>
    <div class="flex flex-col max-w-7xl items-center justify-between pt-6 xl:px-8 xl:mx-auto -mt-16">
      <div
          class="bg-electric-violet-200 text-electric-violet-600 w-full rounded-t-5xl border border-electric-violet-500 pt-16 pb-28 px-6 xl:px-12 grid md:grid-cols-2 gap-x-8 gap-y-16">

        <div>
          <h3 class="text-4xl mb-2">Testimonials</h3>
          <p>Customer reviews and ratings</p>

          <div class="mt-8 flex gap-0.5 mb-2">
            <StarIcon v-for="i in 5" :key="i" class="h-6 w-6"/>
          </div>
          <p>5 star reviews on Google</p>
        </div>

        <div v-for="testimonial in testimonials" :key="testimonial.id">
          <div class="flex gap-0.5">
            <StarIcon v-for="i in testimonial.rating" :key="i" class="h-6 w-6"/>
          </div>

          <blockquote class="mt-2 text-md font-semibold leading-7 tracking-tight sm:text-xl sm:leading-8">
            <p>“{{ testimonial.testimonial }}”</p>
          </blockquote>

          <figure class="mt-5 flex items-center gap-x-6">
            <picture v-if="testimonial.avatar">
              <source :srcset="testimonial.avatar.srcset_webp" type="image/webp">
              <source :srcset="testimonial.avatar.srcset" :type="testimonial.avatar.mime_type">
              <img :alt="'Avatar of ' + testimonial.name" :src="testimonial.avatar.url"
                   class="h-12 w-12 rounded-full bg-gray-50" loading="lazy"
                   onload="if(this.dataset.sized===undefined){this.sizes=Math.ceil(this.getBoundingClientRect().width/window.innerWidth*100)+'vw';this.dataset.sized=''}"
                   sizes="1px">
            </picture>

            <div class="text-sm leading-6">
              <div class="font-semibold">{{ testimonial.name }}</div>
              <div class="mt-0.5">{{ testimonial.job_title }} at {{ testimonial.company }}</div>
            </div>
          </figure>
        </div>

        <div class="col-span-1 col-start-1">
          <h3 class="text-3xl font-bold tracking-tight text-electric-violet-700 sm:text-4xl">Don't just take our word for it</h3>
          <p class="mt-3">
            <Link href="/customer-stories">Read more of our customer stores</Link>
            to see how SAMOS has helped businesses like yours grow and succeed.
          </p>
          <Link
              class="inline-block mt-3 rounded-md bg-electric-violet-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-electric-violet-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-electric-violet-600"
              href="/customer-stories">Customer Stories
          </Link>
        </div>


      </div>

    </div>
  </section>


  <section>
    <div class="flex flex-col max-w-7xl items-center justify-between pt-6 xl:px-8 xl:mx-auto -mt-16">
      <div
          class="bg-pot-pourri-100 text-electric-violet-600 w-full rounded-t-5xl border border-b-0 border-electric-violet-500 pt-16 pb-28 px-6 xl:px-12">
        <div class="max-w-md">
          <h3 class="text-4xl mb-2">Contact us</h3>
          <p>Get in touch to discuss partnering with SAMOS or regarding media or legal queries and we'll get back to you as soon as we can.</p>

          <p class="mt-8">Partnership, press or legal queries</p>
          <p class="mt-3">E: <a class="hover:underline" href="mailto:info@samos-e.com">info@samos-e.com</a></p>
          <p class="mt-3">T: <a class="hover:underline" href="tel:+442034759330">+44(0) 20 3475 9330</a></p>
          <p class="mt-3">A: <a class="hover:underline" href="https://goo.gl/maps/dr9G8DghjqCpGFBW9"
                                target="_blank">51 Eastcheap London, EC3M 1DT, United Kingdom</a></p>
        </div>
      </div>
    </div>
  </section>


</template>
<style scoped>
.samos-drop-shadow {
  filter: drop-shadow(2px 4px 6px rgba(77, 62, 227, 0.4));
}
</style>
